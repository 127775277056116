import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const nav = useNavigate()
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthenticated(true);
            setUserObj();
        }
    }, []);


    const setUserObj = () => {
        const user = localStorage.getItem('user');
        if (user) {
            try {
                const userObj = JSON.parse(user);
                setUser(userObj);
            } catch (error) {
                console.error('Failed to parse user JSON:', error);
            }
        }
    };

    const checkToken = () => {
        const token = localStorage.getItem('token');
        return !!token;
    };
    const getUserFromLocalStorage = () => {
        const user = localStorage.getItem('user');
        try {
            if (user) {
                const parsedUser = JSON.parse(user); // Use a different variable name
                console.log('parsed', typeof (parsedUser));
                return parsedUser;
            }
        } catch (error) {
            console.log("Error:", error);
        }
        return null; 
    };

    const login = (userObj, token) => {
        localStorage.setItem('user', JSON.stringify(userObj));
        localStorage.setItem('token', token);
        setUser(userObj);
        setAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setUser(null);
        setAuthenticated(false);
        nav('/sign-in')
    };

    return (
        <AuthContext.Provider value={{ user, setUserObj, authenticated, checkToken, login, logout, getUserFromLocalStorage }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
