const initialValues = {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    walletAddress: '',
    username: '',
    password: '',
    confirmPassword: '',
    dob: '',
    address: '',
    city: '',
    state: '',
    country: '',
    identificationNumber: '',
    photoID: null,
    proofOfAddress: null
}

export { initialValues }



// const initialValues = {
//     email: 'lodhisherdil1@gmail.com',
//     phone: '123-456-7890',
//     firstName: 'John',
//     lastName: 'Doe',
//     walletAddress: '0xAbC1234567890defABC1234567890DEFabc12345',
//     username: 'john_doe123',
//     password: 'securePassword123!',
//     confirmPassword: 'securePassword123!',
//     dob: '1990-01-01',
//     address: '123 Main St',
//     city: 'Anytown',
//     state: 'Anystate',
//     country: 'Anyland',
//     identificationNumber: 'ID123456789',
//     photoID: null,
//     proofOfAddress: null
// }

// export { initialValues }

