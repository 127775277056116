import React, { useEffect, useState } from "react";
import styles from "../kycForm/step1.styles";
import logo from "../../assets/images/logo/logo.png";
import "./swap.css";
import homeLogo from '../../assets/images/landing/home-logo.png'
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { tokenAbi } from "../../abis/tokenABI";
import { privateSaleABI } from "../../abis/privateSaleABI";
import {
  useReadContract,
  useAccount,
  useWriteContract,
  useBalance,
  useWaitForTransactionReceipt,

} from "wagmi";
import { parseUnits, formatEther } from "viem";
import { getSignature, sendTransactionEmail } from "../../services/user.service";
import { toast } from "react-toastify";

function SwapComponent() {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const {
    data: hash,
    status,
    writeContractAsync,
  } = useWriteContract();

  //Mainnet
  // const privateSaleAddress = "0x661f52A8E0dfbd59a8A303ee7981c2aD3579C671";
  // const USDTAddress = "0x55d398326f99059fF775485246999027B3197955";

  
  const USDTAddress= process.env.REACT_APP_USDT_ADDRESS;
  const privateSaleAddress = process.env.REACT_APP_PRIVATE_SALE_ADDRESS;
  console.log({USDTAddress, privateSaleAddress})

  // testnet USDT: 0x7Ebf49Ae72e54fa813A49BB454A885A37339CE9C 
  //testnet provate Sale: 0xEDdec394d68dC746EeC16f169CD28F8C416D45e1 / 0x8EfA1ac90540206Cd13592f3424F103D635DeCeC

  const [outputAmount, setOutputAmount] = useState("Choose Amount");
  const [inputAmount, setInputAmount] = useState("Choose Amount");
  const [signature, setSignature] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);

  // const { isLoading: isWaitingForApproval, isSuccess: isApprovalSuccess, data, } = useWaitForTransactionReceipt({
  //   hash: transactionHash,

  //   confirmations:20
  // });

  const { refetch: waitForTransactionReceipt } = useWaitForTransactionReceipt();

  async function approveStableCoin(amount) {
    try {
      if (amount > 0) {
        setLoading(true);
        await writeContractAsync(
          {
            address: USDTAddress,
            abi: tokenAbi,
            functionName: "approve",
            args: [privateSaleAddress, parseUnits(amount.toString(), 18)],
            account: address,
          },
          {
            onSettled: async (txnReceipt) => {
              await waitForTransactionReceipt({ hash: txnReceipt })
              console.log("📦 Transaction blockHash", txnReceipt);
              refetchUSDTApprovalAMount()

            },
            onError: (error) => {
              setLoading(false);
            },
          }
        );
      }
    } catch (e) {
      setLoading(false);
      console.error("Error setting greeting", e);
    }
  }

  function getInputAmount(amount) {
    if (amount * 10 ** 18 >= Number(maxAllowed - userBalance) / 2) {
      amount = formatEther((maxAllowed - userBalance).toString()) / 2;

    }

    setOutputAmount(amount);

    if (amount <= 0) {
      setInputAmount(0);
      return;
    }

    let inputAmount = amount / 0.5;
    setInputAmount(Number(inputAmount).toFixed(5));
  }

  const { data: userBalance, refetch: refetchUserBalance } = useReadContract({
    abi: privateSaleABI,
    address: privateSaleAddress,
    functionName: "userBalance",
    args: [address],
  });


  const { data: maxAllowed } = useReadContract({
    abi: privateSaleABI,
    address: privateSaleAddress,
    functionName: "maxBuyAllowed",
  });

  function getOutputAmount(amount) {
    console.log({ maxAllowed, userBalance });

    if (amount * 10 ** 18 >= maxAllowed - userBalance) {
      amount = formatEther((maxAllowed - userBalance).toString());
    }

    setInputAmount(amount);

    if (amount <= 0) {
      setOutputAmount(0);
      return;
    }

    let outputAmount = amount * 0.5;
    setOutputAmount(Number(outputAmount).toFixed(5));
  }

  async function buyTokens(inputAmount, outputAmount) {
    if (!inputAmount || !outputAmount) {
      return;
    }

    if (inputAmount > 0 && outputAmount > 0) {
      try {
        setLoading(true);

        await writeContractAsync(
          {
            address: privateSaleAddress,
            abi: privateSaleABI,
            functionName: "buyREM",
            args: [parseUnits(inputAmount.toString(), 18), signature],
            account: address,
          },
          {
            onSettled: async (txnReceipt) => {
              await waitForTransactionReceipt({ hash: txnReceipt })
              console.log("📦 Transaction blockHash", txnReceipt);
              await sendTransactionEmail(JSON.parse(localStorage.user).email, inputAmount, address, txnReceipt )
              refetchUserBalance()
              refetchUSDTApprovalAMount()

            },
            onError: (error) => {
              console.log("buytoken error: ",error)
              setLoading(false);
            },
          }
        );

      } catch (err) {
        setLoading(false);
        console.log("error in buyTokens", err)

      }


    }
  }

  const { data: USDTApprovalAMount, refetch: refetchUSDTApprovalAMount } = useReadContract({
    abi: tokenAbi,
    address: USDTAddress,
    functionName: 'allowance',
    args: [address, privateSaleAddress]
  })


  useEffect(() => {
    if (status == "success") {
      setLoading(false);

      toast(`Transaction confirmed at: \n ${hash}`, {
        style: styles.swapToast,
        position: "top-right",
        autoClose: false,
        hideProgressBar: true
      });

    }
  }, [userBalance, USDTApprovalAMount]);

  async function Signature() {
    if (localStorage.token) {
      console.log(localStorage.token)

      const data = await getSignature(localStorage.token);
      console.log("signature: ", data)
      setSignature(data.data.signature);
      setWalletAddress(data.data.walletAddress);
    }
  }


  useEffect(() => {
    Signature();
  }, [localStorage]);

  useEffect(() => {
    // Return cleanup function to avoid potential memory leaks
    return () => {
      // Place cleanup logic here if necessary
    };
  }, []);

  return (
    <section style={{ ...styles.sectionBackground, overflow: "hidden" }} className="section-container">


      {/* <div className='homediv'>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ height: "100px", width: "auto" }} src={homeLogo} alt="home-logo" />
        </div>
        <h6 style={{ textAlign: "center", fontSize: "16px", fontWeight: "600", color: "rgb(211 195 140)", marginTop: "5px" }}>HOME</h6>
        <div style={{ display: "block", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "18px", width: "90%" }}>
          <a style={{ color: "inherit" }} href="/REM White Paper V1.0 (D7).pdf" target="_blank">
            <button className='button-LH button-LH-kyc' style={{ fontSize: "14px", fontWeight: "100", color: 'rgb(211 195 140)', borderRadius: "0px", border: "none", height: "44px", width: "100%" }}>
              WHITEPAPER
            </button>
          </a>
          <a style={{ color: "inherit" }} href="/REM TOKENOMICS.pdf" target="_blank">
            <button className='button-LH button-LH-kyc' style={{ fontSize: "14px", fontWeight: "100", color: 'rgb(211 195 140)', borderRadius: "0px", border: "none", height: "44px", width: "100%", marginTop: "10px" }}>
              TOKENOMICS
            </button>
          </a>
        </div>

      </div> */}


      <form
        data-aos="fade-right"
        data-aos-duration="500"
        className="form-container scaling"
        style={{ display: "block", marginTop: "130px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <img
            style={{ height: "100px", width: "133px" }}
            src={logo}
            alt="Logo"
          />
        </div>
        <p
          className="color"
          style={{
            fontSize: "27px",
            textAlign: "center",
            fontWeight: "600",
            margin: "10px 0px -7px 0px",
          }}
        >
          REM TOKENS
        </p>
        <p
          className="color"
          style={{
            fontSize: "18px",
            textAlign: "center",
            fontWeight: "600",
            margin: "10px 0px -7px 0px",
            fontStyle: "italic",
          }}
        >
          Private Sales
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              border: "1px solid grey",
              width: "20%",
              border: "1px solid rgb(184, 136, 61)",
            }}
          ></div>
        </div>
        <div className="form-inner-container marginB">
          <div style={styles.container}>
            <div style={styles.formContainer}>
              <h3 style={styles.step} className="marginB">
                <span
                  style={{
                    ...styles.step,
                    fontStyle: "italic",
                    fontWeight: "700",
                  }}
                ></span>
              </h3>
              <div className="swap-input">
                <div className="input-wrapper">
                  <input
                    style={{ ...styles.input, marginBottom: "0px" }}
                    type="number"
                    id="amount"
                    name="amount"
                    required
                    placeholder={inputAmount}
                    value={inputAmount}
                    onChange={(data) => {
                      getOutputAmount(data.target.value);
                    }}
                    disabled={!isConnected}
                  />

                  <button className="max-button" type="button" onClick={() => {
                    getInputAmount(Number(200000))
                  }}
                    disabled={!isConnected}>
                    MAX
                  </button>
                </div>
                <button
                  className="swap-button"
                  type="button"
                  style={{
                    marginTop: "0px",
                    fontWeight: "bolder",

                    width: "309px",
                  }}
                >
                  <h5 style={{ fontSize: "21px" }}>REM TOKENS</h5>
                </button>
              </div>

              <div className="swap-input">
                <input
                  style={{ ...styles.input, marginBottom: "0px" }}
                  type="number"
                  id="price"
                  name="price"
                  required
                  placeholder={outputAmount}
                  value={outputAmount}
                  onChange={(data) => {
                    getInputAmount(data.target.value);
                  }}
                  disabled={!isConnected}
                />
                <button
                  className="swap-button"
                  type="button"
                  style={{
                    marginTop: "0px",
                    fontWeight: "bolder",
                    width: "320px",
                  }}
                >
                  <h5 style={{ fontSize: "21px" }}>USDT</h5>
                </button>
              </div>

              <h3 style={styles.step} className="price">
                <span
                  style={{
                    ...styles.step,
                    fontStyle: "italic",
                    fontWeight: "700",
                  }}
                >
                  Price :
                </span>{" "}
                1 Rem token = 0.50 USDT
              </h3>

              {isConnected ? <h3 style={styles.step} className="price">
                <span
                  style={{
                    ...styles.step,
                    fontStyle: "italic",
                    fontWeight: "700",
                  }}
                >
                  User Balance :
                </span>{" "}
                {`${userBalance>0?(Number(userBalance) / 10 ** 18).toFixed(2):0}`}
              </h3> : <></>}


            </div>
          </div>
        </div>


        <div style={{ margin: "-20px 0px 20px 0px" }}>
          {loading ? <>
            <button
              className="buy-tokens-button swap-button"
              type="button"
              style={{
                opacity: "0.7",
                marginTop: "0px",
                fontWeight: "bolder",
                height: "56px",
                width: "320px"
              }}
              disabled={loading}
            >
              <h5 style={{ fontSize: "21px", marginLeft: "146px" }} className="loader" ></h5>
            </button></> : <>

            {isConnected ? (
              <>
              {console.log({address, walletAddress})}
                {address.toLocaleLowerCase() == walletAddress.toLocaleLowerCase() ? (
                  <>
                    {Number(outputAmount * 10 ** 18) <= Number(USDTApprovalAMount) ? (
                      <button
                        className="buy-tokens-button swap-button"
                        type="button"
                        style={{
                          marginTop: "0px",
                          fontWeight: "bolder",
                          height: "56px",
                        }}
                        onClick={() => {
                          buyTokens(inputAmount, outputAmount);
                        }}
                      >
                        <h5 style={{ fontSize: "21px" }}>BUY TOKENS</h5>
                      </button>
                    ) : (
                      <button
                        className="buy-tokens-button swap-button"
                        type="button"
                        style={{
                          marginTop: "0px",
                          fontWeight: "bolder",
                          height: "56px",
                          width: "320px"
                        }}
                        onClick={() => {
                          approveStableCoin(outputAmount);
                        }}
                      >
                        <h5 style={{ fontSize: "21px" }}>Approve USDT</h5>
                      </button>
                    )}
                  </>

                ) : (<button
                  className="buy-tokens-button swap-button"
                  type="button"
                  style={{ marginTop: "0px", fontWeight: "bolder", height: "56px", width: "320px" }}
                  onClick={() => {
                    open();
                  }}
                >
                  <h5 style={{ fontSize: "21px" }}>Wrong Address</h5>
                </button>)}
              </>
            ) : (
              <button
                className="buy-tokens-button swap-button"
                type="button"

                style={{ marginTop: "0px", fontWeight: "bolder", height: "56px" }}
                onClick={() => {
                  open();
                }}
              >
                <h5 style={{ fontSize: "21px" }}>Connect Wallet</h5>
              </button>
            )}

          </>}
        </div>
      </form>
    </section>
  );
}

export default SwapComponent;
