import React, { useState, useEffect } from 'react';
import './kycform.css';
import styles from './step1.styles';
import { getCountries, getCities } from 'countries-cities';

const Step3 = ({ formData, handleChange }) => {
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        setCountries(getCountries());
    }, []);

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        handleChange(e);
        setCities(getCities(selectedCountry));
    };

    return (
        <div style={styles.formContainer}>
            <h2 style={styles.step}><span style={{ ...styles.step, fontWeight: "400" }}>Step 3:</span> Customer Identification</h2>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                <input
                    style={{ ...styles.input, flex: '1 1 45%' }}
                    className='formInput'
                    type="text"
                    disabled
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                />
                <input
                    style={{ ...styles.input, flex: '1 1 45%' }}
                    className='formInput'
                    type="text"
                    name="lastName"
                    disabled
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                />
            </div>

            <div style={{ borderRadius: "5px", display: "flex", margin: "10px 0px", alignItems: "center", border: '1px solid rgb(184,136,61)', height: "55px" }}>
                <span style={{ flex: ".6", color: "rgb(186, 186, 186)", fontSize: "16px", paddingBottom: "-17px", marginLeft: "10px" }}>Date Of Birth:</span>
                <input
                    style={{ ...styles.input, flex: "2", border: 'none', marginTop: "13px" }}
                    className='formInput'
                    type="date"
                    name="dob"
                    color='green'
                    placeholder="Date of Birth"
                    value={formData.dob}
                    onChange={handleChange}
                />
            </div>

            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                <input
                    style={{ ...styles.input, flex: '1 1 45%' }}
                    className='formInput'
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                />
                <select
                    style={{ ...styles.input, flex: '1 1 45%', color: 'lightgrey', background: 'none', appearance: 'none', cursor: 'pointer', fontSize: '14px', padding: '10px' }}
                    className='formInput'
                    name="country"
                    value={formData.country}
                    onChange={handleCountryChange}
                >
                    <option className='dropdown-option' value="">Select Country</option>
                    {countries.map((country) => (
                        <option className='dropdown-option' key={country} value={country}>{country}</option>
                    ))}
                </select>
            </div>

            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                <select
                    style={{ ...styles.input, flex: '1 1 45%', color: 'lightgrey', background: 'none', appearance: 'none', cursor: 'pointer', fontSize: '14px', padding: '10px' }}
                    className='formInput'
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                >
                    <option className='dropdown-option' value="">Select City</option>
                    {cities?.map((city) => (
                        <option key={city} value={city} className="dropdown-option">{city}</option>
                    ))}
                </select>
                <input
                    style={{ ...styles.input, flex: '1 1 45%' }}
                    className='formInput'
                    type="text"
                    name="state"
                    placeholder="State"
                    value={formData.state}
                    onChange={handleChange}
                />
            </div>

            <input
                style={styles.input}
                className='formInput'
                type="text"
                name="identificationNumber"
                placeholder="ID / Passport number"
                value={formData.identificationNumber}
                onChange={handleChange}
            />
        </div>
    );
};

export default Step3;
