import React, { useState, useEffect } from 'react';
import styles from './step1.styles';
import { sendVerificationCode, verifyUserCode } from '../../services/user.service';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import './kycform.css'
import { useNavigate } from 'react-router-dom';

const Step1 = ({ formData, handleChange, nextStep }) => {
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationInput, setShowVerificationInput] = useState(false);
    const [reCapVal, setReCapVal] = useState(null);
    const nav = useNavigate()

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleSendClick = async () => {
        try {
            const validation = validateFields(showVerificationInput, formData, toast);
            if (!validation) {
                return;
            }
            const email = formData.email;
            if (!email) {
                toast('Please enter an email address', styles.toastStyles);
                return;
            }
            if (!reCapVal) {
                return toast.error('Please confirm if you are not a robot', { position: 'top-right' });
            }
            setLoading(true);
            setShowVerificationInput(false);
            const response = await sendVerificationCode(email, formData.phone);            
            if(response.alreadyExist == false){
                setLoading(false);
                return
            }
            toast(response.message, styles.toastStyles)
            setLoading(false);
            if (response.formSubmission) {
                return toast('Form already submitted. We will notify you after verification via email or phone.', {
                    ...styles.toastStyles,
                    autoClose: 6000,
                });
            }
            setShowVerificationInput(true);
            if (response.emailVerification === false && response.codeSent === true) {
                setCountdown(60);
            } else if (response.emailVerification === true) {
                nextStep();
            }
        } catch (error) {
            setLoading(false);
            console.error('Error sending verification code to your email:', error);
            // toast(error.message || error ,styles.toastStyles);
        }
    };

    const handleVerificationCodeChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) { // Restrict to 6 digits
            setVerificationCode(value);
        }
    };

    const handleSubmitCode = async (e) => {
        try {
            setLoading(true)
            const email = formData.email;
            const code = verificationCode;

            if (!email || !formData.phone) {
                setLoading(false)
                toast.error('Please enter both email and Cell Number', { position: 'top-right' });
                return;
            }
            if (!code) {
                setLoading(false)
                return toast.error('Please enter the verification code', { position: 'top-right' });
            }

            if (!reCapVal) {
                setLoading(false)
                return toast.error('Please confirm if you are not a robot', { position: 'top-right' });
            }

            const response = await verifyUserCode(email, code, formData.phone);
            setLoading(false)
            if (response.emailVerification === true) {
                toast('Verification successful', styles.toastStyles);
                nextStep();
            } else {
                toast.error('Verification failed. Please check your code and try again.', { position: 'top-right' });
            }
        } catch (error) {
            console.log('Error verifying code:', error);
            toast(error.response.data.message || 'Verification failed', styles.toastStyles);
            setLoading(false)
        }
    };

    const validateFields = (showVerificationInput, formData, toast, reSendButton) => {
        const requiredFields = ['email', 'phone', ...(showVerificationInput && !reSendButton ? ['verificationCode'] : [])];
        let isValid = true;

        requiredFields.forEach(field => {
            if (!formData[field]) {
                toast.error(`Please enter ${field}`, { position: 'top-right' });
                isValid = false;
            } else if (field === 'phone' && !/^\+[0-9]+$/.test(formData[field])) {
                toast.error('Phone number should start with "+" and contain only numbers', { position: 'top-right' });
                isValid = false;
            }
        });

        return isValid;
    };

    return (
        <div style={styles.container}>
            <div style={styles.formContainer}>
                <h3 style={styles.step} className='marginB'><span style={{ ...styles.step, fontStyle: 'italic', fontWeight: "400" }}>Step 1</span>: Verification</h3>
                <div style={styles.form}>
                    <input
                        style={styles.input}
                        placeholder='Email: example@exmaple.com'
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {showVerificationInput && (
                        <input
                            style={styles.input}
                            placeholder='Enter 6 digit verification code'
                            type="text"
                            id="verificationCode"
                            name="verificationCode"
                            value={verificationCode}
                            onChange={handleVerificationCodeChange}
                            required
                        />
                    )}
                    <input
                        style={styles.input}
                        placeholder='Cell: +15854997875'
                        type="text"
                        id="cell"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        // pattern="[0-9]*"  // Only allow numbers
                        required
                    />
                    <div style={{ display: showVerificationInput ? 'block' : 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        {
                            !reCapVal &&
                            <div data-aos="fade-right" data-aos-duration="500" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", gap: "10px" }}>
                                <ReCAPTCHA
                                    className='recap'
                                    sitekey={process.env.REACT_APP_RECAPTCHA_DEV} // Replace with your sitekey
                                    onChange={(val) => setReCapVal(val)}
                                />
                                <div>

                                    <button data-aos="fade-right" data-aos-duration="500"
                                        onClick={handleSendClick}
                                        type='button'
                                        style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", width: "180px" }}
                                    >
                                        <h5 className="f-w-700" style={{ fontSize: '18px', fontWeight: "700", height: "25px" }}>SEND</h5>
                                    </button>
                                    <p style={styles.alreadyButton} onClick={() => nav('/sign-in')}>Already have an account ?</p>
                                </div>

                            </div>
                        }
                        <div style={{ width: '100%', flex: '1', display: 'flex', flexDirection: "column", alignItems: showVerificationInput ? "center" : 'end', justifyContent: "center" }}>
                            {loading ? (
                                <button
                                    style={{ ...styles.button, marginTop: "0px", marginLeft: "10px", fontWeight: "bolder", opacity: "0.6", width: '180px' }}
                                    disabled
                                >
                                    <div style={{ marginLeft: "70px" }} className="loader"></div>
                                </button>
                            ) : (
                                <div style={{ display: showVerificationInput ? "flex" : "block", marginTop: showVerificationInput ? '25px' : '' }} className="">
                                    {showVerificationInput ? (
                                        <>
                                            {/* <button type='button'
                                                onClick={countdown > 0 ? null : handleSendClick}
                                                style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", minWidth: '180px', opacity: countdown > 0 ? 0.6 : 1 }}
                                                disabled={countdown > 0}
                                            >
                                                <h5 style={{ fontSize: '18px' }}>
                                                    {countdown > 0 ? `Resend in ${countdown}s` : 'SEND'}
                                                </h5>
                                            </button> */}
                                            <button type='button'
                                                onClick={handleSubmitCode}
                                                style={{ ...styles.button, marginTop: "0px", marginLeft: "10px", fontWeight: "bolder", minWidth: "180px" }}
                                            >
                                                <h5 className='buttpn-text' >{loading ? <div style={{ marginLeft: "70px" }} className="loader"></div>
                                                    : 'SUBMIT CODE'}</h5>
                                            </button>
                                        </>
                                    ) : reCapVal && (
                                        <>
                                            <button data-aos="fade-right" data-aos-duration="500"
                                                onClick={handleSendClick}
                                                type='button'
                                                style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", width: "180px" }}
                                            >
                                                <h5 className="f-w-700 buttpn-text" >SEND</h5>
                                            </button>
                                            <p style={styles.alreadyButton} onClick={() => nav('/sign-in')}>Already have an account ?</p>
                                        </>

                                    )}
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step1;
