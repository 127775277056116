import leftBg from '../../assets/images/landing/left-bg.jpg';

const styles = {
    toastStyles: {
        position: "top-right",
        className: "toast-message"
    },
    container: {
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        backgroundImage: `url(${leftBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)', 
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    formContainerResp: {
        padding: '10px 10px', // Increased padding for better spacing
        gap: '20px', // Gap between columns
        borderRadius: '10px',
        width: "100%",
        textAlign: 'center',
        fontFamily: 'Aptos, sans-serif', // Example font family
        color: '#fff', // Text color for the form container
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)', // Box shadow for the form container
        
    },
        formContainer: {
            // backgroundImage: `url(${leftBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            // backgroundColor: 'rgba(255, 255, 255, 0.5)', 
            display: "grid",
            gridTemplateColumns: '1fr 1fr', // 2 columns layout
            paddingBottom:'0px',
            paddingRight:"50px",
            paddingLeft:"50px",
            gap: '20px', // Gap between columns
            borderRadius: '10px',
            width: "100%",
            textAlign: 'center',
            fontFamily: 'Aptos, sans-serif', // Example font family
            color: '#fff', // Text color for the form container
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)', // Box shadow for the form container
            
        },

    leftSection: {
        height: "75vh",
        padding: '20px 0px', // Padding for left section content
        borderRadius: '10px', // Border radius for left section
        display: 'flex',
        marginTop:"20px",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start', // Align items to the start (left)
        textAlign: 'left', // Align text to the left
    },

    leftSectionResp: {
        marginTop:"90px",
        height: "70vh",
        borderRadius: '10px', // Border radius for left section
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Align items to the start (left)
        textAlign: 'left', // Align text to the left
    },


    rightSection: {
        padding: '20px', // Padding for right section content
        borderRadius: '10px', // Border radius for right section
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    rightSectionResp: {
        scale:0.7,
        padding: '20px', // Padding for right section content
        borderRadius: '10px', // Border radius for right section
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    mainHeading: {
        marginBottom: '33px', // Bottom margin for the main heading
        marginTop:"1px",
        lineHeight: '1.2', // Adjust line height to reduce gap between lines

    },
    subHeading: {
        fontSize: '18px', // Font size for the subheading in left section
        marginBottom: '10px', // Bottom margin for the subheading
    },
    divider: {
        width: "100%",
        height: "1px",
        background: "linear-gradient(to right, rgb(216 216 216) 10%, transparent 15%)",
    },
    dividerResp: {
        display:"none"
    },

    button: {
        height: "50px",
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#92D050',
        color: '#000',
        background: 'rgb(153, 101, 19)',
        background: 'linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgba(214, 163, 59, 1) 100%)',
        fontWeight: 'bold',
        width: "80%",
        cursor: 'pointer',
        marginTop: "20px",
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    chooseFileButton: {
        height: "50px",
        width: "30%",
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#92D050',
        color: '#000',
        background: 'rgb(153, 101, 19)',
        background: 'linear-gradient(180deg, rgba(153, 101, 19, 1) 0%, rgba(214, 163, 59, 1) 100%)',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginTop: "20px",
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
};

export default styles;
