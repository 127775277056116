import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import menus from '../../pages/menu';
import '../../components/landing-page/lp.css';
import './styles.scss';
import remLogo from '../../assets/images/logo/main-logo.png';
import { useAuth } from '../contextAPI/AuthContext';

const Header = () => {
    const { authenticated, logout, checkToken, getUserFromLocalStorage } = useAuth();
    const nav = useNavigate();
    const [scroll, setScroll] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [hideheader, setHideHeader] = useState(true);
    const [userObj, setUserObj] = useState({});
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const location = useLocation();
    const { pathname } = location;



    const isSignUpMobileView = () =>( pathname === '/sign-up' ||  pathname === '/private-sale' ||  pathname === '/' )&& screenWidth < 1025;


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        const handleScroll = () => {
            setScroll(window.scrollY > 300);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const handleDropdown = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    const filteredMenus = menus.filter((menu) => {
        if (menu.showWhenLoggedIn && authenticated) {
            return menu.showOnLgScreen || screenWidth <= 1024;
        }
        if (menu.name === 'TOKENOMICS' || menu.name === 'WHITE PAPER') {
            return isSignUpMobileView();
        }
        return !menu.showWhenLoggedIn && !authenticated;
    });



    useEffect(() => {
        if (checkToken) {
            setUserObj(getUserFromLocalStorage());
        }

        if (pathname === '/sign-in' || pathname === '/forgot-password' || ((pathname === '/sign-up' || pathname === '/')&& screenWidth > 1025)) {

            setHideHeader(true);
        } else {
            setHideHeader(false);
        }
    }, [pathname, screenWidth]);

    return (
        !hideheader ?
            <header style={{ background: isSignUpMobileView() ? 'none' : 'none' }} className={`header ${scroll ? 'is-fixed' : ''}`}>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="site-header-inner">
                                <div id="site-logo" className="clearfix">
                                    {/* {
                                        isSignUpMobileView() ? '' :
                                            <div id="site-logo-inner">
                                                <Link to="/" rel="home" className="main-logo">
                                                    <img
                                                        style={{ height: '85px', width: '125px' }}
                                                        id="logo_header"
                                                        className="logo-dark flip-animation"
                                                        src={remLogo}
                                                        alt="Binasea"
                                                    />
                                                </Link>
                                            </div>
                                    } */}
                                </div>

                                <div className="header-right" style={{ gap: "4px" }}>
                                    {/* small menu items start */}
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} className="header-center">
                                        <nav
                                            id="main-nav"
                                            className={`main-nav ${menuActive ? 'active' : ''}`}
                                        >
                                            <ul
                                                key={filteredMenus}
                                                id="menu-primary-menu"
                                                className="menu"
                                            >
                                                {/* Conditional Sign In and Sign Up */}
                                                {/* {!authenticated && (
                                                    <>
                                                        <li className="menu-item f-w-700">
                                                            <Link className='f-w-700 nova-font' style={{ fontSize: window.innerWidth > 1024 ? "20px" : '', fontWeight: "700" }} to="/">Home</Link>
                                                        </li>
                                                    </>
                                                )} */}
                                                {/* Dynamic Menu Items */}
                                                {filteredMenus.map((data, idx) => (
                                                    <li
                                                        key={idx}
                                                        onClick={() => handleDropdown(idx)}
                                                        className={`menu-item f-w-700 ${data.namesub ? 'menu-item-has-children f-w-700' : ''} ${activeIndex === idx ? 'active' : ''}`}
                                                    >

                                                        <Link target={data.pdf ? `_blank` : null} className='nova-font' style={{ fontSize: window.innerWidth > 1024 ? "20px" : '', fontWeight: "700" }} to={data.links}>{data.name}</Link>
                                                        {/* Submenus (if any) */}
                                                        {data.namesub && (
                                                            <ul className="sub-menu">
                                                                {data.namesub.map((submenu) => (
                                                                    <li
                                                                        key={submenu.id}
                                                                        className="menu-item f-w-700"
                                                                    >
                                                                        <NavLink to={submenu.links}>
                                                                            {submenu.sub}
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </li>
                                                ))}

                                                {/* Conditional Sign In and Sign Up */}
                                                {!authenticated && window.innerWidth < 1024 && (
                                                    <>
                                                        <li className="menu-item f-w-700">
                                                            <Link className='nova-font' style={{ fontWeight: "700" }} to="/sign-in">Sign In</Link>
                                                        </li>
                                                        <li className="menu-item f-w-700">
                                                            <Link className='nova-font' style={{ fontWeight: "700" }} to="/sign-up">REGISTER</Link>
                                                        </li>
                                                    </>
                                                )}
                                                {authenticated && window.innerWidth < 1024 && (
                                                    <>
                                                        <li onClick={() => logout()} className="menu-item f-w-700">
                                                            <Link className='nova-font' style={{ fontWeight: "700" }} to="/sign-in">Log Out</Link>
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </nav>
                                    </div>
                                    {/* small menu items end */}
                                    {
                                        authenticated ? (
                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                <button onClick={() => logout()} className="f-w-700 button-LH resp text-shadow button-text-LH" style={{ width: "120px", height: "60px", marginTop: "20px" }}>
                                                    <h6 className='f-w-700 nova-font'>
                                                        LOGOUT
                                                    </h6>
                                                </button>
                                                {/* <button className="f-w-700 button-LH resp text-shadow button-text-LH" style={{ width: "120px", height: "60px", marginTop: "0px", background: "rgb(116 182 45)" }}>
                                                    <h6 style={{ color: "black", textShadow: "none" }} className='f-w-700 nova-font'>
                                                        CONNECT
                                                    </h6>
                                                </button> */}
                                            </div>
                                        ) : (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", marginRight: "10px" }}>
                                                    <button onClick={() => nav('/sign-in')} className="resp  f-w-700 button-LH resp text-shadow button-text-LH" style={{ width: "135px", height: "60px", marginTop: "0px", marginRight: "10px" }}>
                                                        <h6 className='f-w-700 nova-font' style={{ fontSize: '18px' }}>
                                                            LOG IN
                                                        </h6>
                                                    </button>

                                                    <button onClick={() => nav('/sign-up')} className="resp  f-w-700 button-LH resp text-shadow button-text-LH" style={{ width: "135px", height: "60px", marginTop: "0px" }}>
                                                        <h6 className='f-w-700 nova-font' style={{ fontSize: '18px' }}>
                                                            REGISTER
                                                        </h6>
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header> : <></>
    );
};

export default Header;
