import React, { useRef, useState } from 'react';
import styles from './step1.styles';
import { toast } from 'react-toastify';

const FileInput = ({ label, name, handleChange }) => {
    const fileInputRef = useRef(null);
    const [fileSelected, setFileSelected] = useState(false);
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            // if (!validImageTypes.includes(file.type)) {
            //     toast('Please select an image file (jpg, png, gif)',styles.toastStyles);
            //     return;
            // }

            const e = {
                target: {
                    name: name,
                    value: file
                }
            };
            handleChange(e); 
            setFileSelected(true); 
            setFileName(file.name);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', gap: '20px' }}>
                <label style={{ marginRight: 'auto' }}>{label}</label>
                <button type='button' onClick={handleButtonClick} style={{ ...styles.chooseFileButton, backgroundColor: fileSelected ? '#333' : '#666' }}>
                    {fileSelected ? 'Change File' : 'Choose File'}
                </button>
                <input
                    ref={fileInputRef}
                    id="file"
                    type="file"
                    accept="image/*" // Accepts only image files
                    onChange={handleFileChange}
                    style={{ display: 'none' }} // Hide the input visually
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {fileSelected && (
                    <span>{fileName}</span>
                )}
            </div>
        </>
    );
};

export default FileInput;
