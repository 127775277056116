import React, { useState, useEffect } from "react";
import logo from '../assets/images/logo/logo.png';
import { resetPassword, sendFpCode, verifyFpCode } from "../services/user.service";
import { toast } from "react-toastify";
import styles from "../components/kycForm/step1.styles";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
    const nav = useNavigate();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    useEffect(() => {
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/;
        // setIsPasswordValid(passwordRegex.test(password));
        // setPasswordsMatch(password === confirmPassword && password.length > 0);
    }, [password, confirmPassword]);

    const handleSendCode = async () => {
        setLoading(true);
        try {
            const response = await sendFpCode(email);
            toast(response.message, styles.toastStyles);
            setShowCodeInput(true);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const verifyCode = async () => {
        setLoading(true);
        try {
            const response = await verifyFpCode(email, code);
            toast(response.message, styles.toastStyles);
            console.log(response.fpVerificationCode, 'asd');
            if (response.fpVerificationCode) {
                setShowCodeInput(false)
                setShowResetPassword(true);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async () => {

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/;
        setIsPasswordValid(passwordRegex.test(password));
        console.log(
            password,
            confirmPassword,
            password.length,
            password.length > 0,
            password === confirmPassword
        );
        setPasswordsMatch((password == confirmPassword) && password.length > 0);

        if (!isPasswordValid) {
            // Show toast message if the password is not valid
            toast.error('Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character like @$!%*?&');
            return;
        }

        if (!passwordsMatch) {
            // Show toast message if passwords do not match
            toast.error('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const response = await resetPassword(email, password, code);
            toast(response.message, styles.toastStyles);
            nav('/sign-in');
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <section style={styles.sectionBackground} className="section-container">
            <form data-aos="fade-right" data-aos-duration="500" className="form-container" onSubmit={(e) => e.preventDefault()} style={{ display: "block" }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '100%', width: '100%', marginTop: "20px" }}>
                    <img className="flip-animation" style={{ height: "100px", width: '110px' }} src={logo} alt="Logo" />
                </div>
                <p className='color' style={{ fontSize: "23px", textAlign: "center", fontWeight: "600", margin: "10px 0px 27px 0px" }}>
                    {showResetPassword ? `Reset Password` : `FORGOT PASSWORD`}
                </p>
                <div className="form-inner-container marginB">
                    <div style={styles.container}>
                        <div style={styles.formContainer}>
                            <div style={styles.form}>
                                {showCodeInput ?
                                    <input
                                        style={styles.input}
                                        placeholder='Enter 6 digit code'
                                        type="number"
                                        id="code"
                                        name="code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        required
                                    />
                                    : showResetPassword ? (
                                        <div>
                                            <input
                                                style={styles.input}
                                                placeholder='Password:'
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <input
                                                style={styles.input}
                                                placeholder='Confirm Password:'
                                                type="password"
                                                id="confirm-password"
                                                name="confirm-password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                            {/* {!isPasswordValid && password && (
                                                <p style={{ color: 'red' }}>Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character like @$!%*?&</p>
                                            )} */}
                                        </div>
                                    ) :
                                        <input
                                            style={styles.input}
                                            placeholder='Email:'
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ padding: "0px 30px", display: 'flex', justifyContent: "center", marginTop: "-15px", marginBottom:"10px"}} className="button-container marginB">
                    {
                        showCodeInput ?

                            <button
                                type="button"
                                onClick={verifyCode}
                                style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", width: "180px", opacity: loading ? 0.6 : 1 }}
                                disabled={loading}
                            >
                                {loading ?
                                    <div style={{ marginLeft: "66px" }} className="loader"></div> :
                                    <h5 className="f-w-700 buttpn-text">SUBMIT CODE</h5>
                                }
                            </button> :

                            showResetPassword ? (
                                <button
                                    type="button"
                                    onClick={handleResetPassword}
                                    style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", width: "168px", opacity: loading ? 0.6 : 1 }}
                                >
                                    {loading ?
                                        <div style={{ marginLeft: "41px" }} className="loader"></div> :
                                        <h5 className="f-w-700 buttpn-text" style={{ width: "137px", marginLeft: "7px" }}>Reset Password</h5>
                                    }
                                </button>
                            ) :

                                <button
                                    type="button"
                                    onClick={handleSendCode}
                                    style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", width: "130px", opacity: loading ? 0.6 : 1 }}
                                    disabled={loading}
                                >
                                    {loading ?
                                        <div style={{ marginLeft: "41px" }} className="loader"></div> :
                                        <h5 className="f-w-700 buttpn-text" style={{ width: "101px", marginLeft: "7px" }}>SEND CODE</h5>
                                    }
                                </button>
                                
                    }
                    
                </div>
                <p style={{fontSize: "14px", fontStyle: "", cursor: "pointer",textAlign:"center",marginBottom:"20px"}} onClick={() => nav('/sign-in')}>Remember Password?</p>


            </form>
        </section>
    );
}

export default ForgotPassword;
