import React, { useEffect, useState } from 'react';
import Step1 from '../components/kycForm/Step1';
import Step2 from '../components/kycForm/Step2';
import Step3 from '../components/kycForm/Step3';
import Step4 from '../components/kycForm/Step4';
import '../components/kycForm/kycform.css'; // Import the CSS file
import logo from '../assets/images/logo/logo.png';
import styles from '../components/kycForm/step1.styles';
import Aos from 'aos';
import { toast } from 'react-toastify';
import { handleStep3Validation, validateFormFields, validateStep3Form, validateStep4Form } from '../components/kycForm/kyc.methods';
import { confirmWalletAddress, saveFormData } from '../services/user.service';
import { initialValues } from '../components/kycForm/kyc.consts';
import homeLogo from '../../src/assets/images/landing/home-logo.png'

function Kyc() {
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialValues);

    useEffect(() => {
        Aos.refresh();
    }, [step]); // Refresh AOS on step change

    // Step 2 validation rules
    const validationRules = {
        firstName: { required: true },
        lastName: { required: true },
        walletAddress: {
            required: true,
            pattern: /^(0x)?[0-9a-fA-F]{40}$/
        },
        username: { required: true },
        password: {
            required: true,
            minLength: 8,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        },
        confirmPassword: {
            required: true,
            pattern: new RegExp(`^${formData.password}$`) // Must match password
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const nextStep = () => {
        if (step === 2) {
            return handleStep2Validation();
        } else if (step === 3) {
            return handleStep3Validation(formData, setStep, step, setLoading, loading);
        } else {
            setStep(step + 1);
        }
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (step === 4) {
            setLoading(true)
            const isValid = validateStep4Form(formData, setErrors, setStep, step);
            if (isValid) {
                try {
                    const resp = await saveFormData(formData);
                    setTimeout(() => {
                        setFormData(initialValues)
                        setStep(1)
                    }, 1000);
                } catch (error) {
                    toast.error("Error submitting form: " + error.message, { position: "top-right" });
                    setLoading(false)
                }
            }
            setLoading(false)
        }
    };

    const handleStep2Validation = async () => {
        const formErrors = validateFormFields(formData, validationRules);
        try {
            setLoading(true)
            await confirmWalletAddress(formData.walletAddress)
            setLoading(false)
        } catch (error) {
            console.log(error);
            toast(`${error.response.data.message}`, styles.toastStyles)
            setLoading(false)
            return
        }
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            Object.values(formErrors).forEach(error => toast(error, styles.toastStyles));
        } else {
            setErrors({});
            setStep(step + 1);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return <Step1 nextStep={nextStep} formData={formData} handleChange={handleChange} />;
            case 2:
                return <Step2 formData={formData} handleChange={handleChange} />;
            case 3:
                return <Step3 formData={formData} handleChange={handleChange} />;
            case 4:
                return <Step4 formData={formData} handleChange={handleChange} />;
            default:
                return null;
        }
    };

    return (
        <section style={styles.sectionBackground} className="section-container">

            <div className='homediv'>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img style={{ height: "100px", width: "auto" }} src={homeLogo} alt="home-logo" />
                </div>
                <h6 style={{ textAlign: "center", fontSize: "16px", fontWeight: "600", color: "rgb(211 195 140)",marginTop:"5px" }}>HOME</h6>
                
                <div style={{ display: "block", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "18px" ,width:"90%"}}>
                    <a style={{ color: "inherit" }} href="/REM White Paper V1.0 (D7).pdf" target="_blank">
                        <button className='button-LH button-LH-kyc' style={{ fontSize:"14px",fontWeight:"100",color: 'rgb(211 195 140)', borderRadius: "0px", border: "none", height: "44px", width: "100%" }}>
                            WHITEPAPER
                        </button>
                    </a>
                    <a style={{ color: "inherit" }} href="/REM TOKENOMICS.pdf" target="_blank">
                        <button className='button-LH button-LH-kyc' style={{ fontSize:"14px",fontWeight:"100",color: 'rgb(211 195 140)', borderRadius: "0px", border: "none", height: "44px", width: "100%",marginTop:"10px" }}>
                            TOKENOMICS
                        </button>
                    </a>
                </div>

            </div>

            <form key={step} data-aos="fade-right" data-aos-duration="500" className="form-container">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '100%', width: '100%', marginTop: "20px" }}>
                    <img className="flip-animation" src={logo} alt="Logo" />
                </div>
                <p className='color' style={{ fontSize: "23px", textAlign: "center", fontWeight: "600", margin: "10px 0px -7px 0px" }}>
                    CREATE ACCOUNT - REM TOKENS
                </p>
                <div className="form-inner-container marginB">
                    {renderStep()}
                </div>
                <div style={{ padding: "0px 30px", display: step === 1 ? "none" : "flex" }} className="button-container marginB">
                    {step > 1 && (
                        <button disabled={loading} type="button" onClick={prevStep} style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", opacity: loading ? 0.6 : 'initial' }}>
                            <h5 className={`f-w-700 ${!loading ? 'buttpn-text' : 'buttpn-text'}`}>
                                BACK
                            </h5>
                        </button>

                    )}
                    {step < 4 && step > 1 && (
                        <button disabled={loading} type="button" onClick={nextStep} style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", opacity: loading ? 0.6 : 'initial' }}>
                            <h5 className={`f-w-700 ${!loading ? 'buttpn-text' : ''}`}>
                                {loading ? (<div style={{ marginLeft: "90px" }} className="loader"></div>) : `NEXT`}
                            </h5>
                        </button>
                    )}
                    {step === 4 ? (
                        loading ? (
                            <button disabled type="submit" style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", opacity: 0.6 }}>
                                <div style={{ marginLeft: "90px" }} className="loader"></div>
                            </button>
                        ) : (
                            <button onClick={handleSubmit} type="submit" style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder" }}>
                                <h5 className={`f-w-700 ${!loading ? 'buttpn-text' : ''}`}>
                                    Submit
                                </h5>
                            </button>
                        )
                    ) : null}

                </div>
            </form>
        </section>
    );
}

export default Kyc;
