import { toast } from 'react-toastify';
import styles from './step1.styles';
import { confirmIdentificationNumber } from '../../services/user.service';
//  Step1 Validation
const validateFields = (showVerificationInput, formData, toast, reSendButton) => {
    const requiredFields = ['email', 'phone', ...(showVerificationInput && !reSendButton ? ['verificationCode'] : [])];
    let isValid = true;

    requiredFields.forEach(field => {
        if (!formData[field]) {
            toast.error(`Please enter ${field}`, { position: 'top-right' });
            isValid = false;
        } else if (field === 'phone' && !/^\+\d+$/.test(formData[field])) {
            toast.error('Phone number should start with "+" and contain only numbers', { position: 'top-right' });
            isValid = false;
        } else if (field === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData[field])) {
            toast.error('Please enter a valid email address', { position: 'top-right' });
            isValid = false;
        }
    });

    return isValid;
};




// Step2 validation
const validateFormFields = (formData, validationRules) => {
    const errors = {};

    Object.keys(validationRules).forEach(field => {
        const value = formData[field];
        const rules = validationRules[field];

        if (rules.required && !value) {
            errors[field] = `${field} is required`;
        } else if (rules.pattern && !rules.pattern.test(value)) {
            if (field === 'password') {
                toast.error('Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character like @$!%*?&', {
                    position: 'top-right',
                });
            }
            if (field === 'walletAddress') {
                toast.error('Wallet address must start with "0x" and consist of 40 hexadecimal characters (0-9, a-f)', {
                    position: 'top-right',
                });
            }

            errors[field] = `${field} is invalid`;
        } else if (rules.minLength && value.length < rules.minLength) {
            errors[field] = `${field} must be at least ${rules.minLength} characters`;
        }
    });

    return errors;
};
// step3 Validation

// Function to validate Step 3 form fields
const validateStep3Form = (formData) => {
    const errors = {};

    // Example validation rules (adjust as per your requirements)
    if (!formData.firstName.trim()) {
        errors.firstName = 'First Name is required';
    }
    if (!formData.lastName.trim()) {
        errors.lastName = 'Last Name is required';
    }
    if (!formData.dob.trim()) {
        errors.dob = 'Date of Birth is required';
    }
    if (!formData.address.trim()) {
        errors.address = 'Address is required';
    }
    if (!formData.city.trim()) {
        errors.city = 'City is required';
    }
    if (!formData.state.trim()) {
        errors.state = 'State is required';
    }
    if (!formData.country.trim()) {
        errors.country = 'Country is required';
    }
    if (!formData.identificationNumber.trim()) {
        errors.identificationNumber = 'Identification Number is required';
    }

    return errors;
};

// step4 Valiadtion

const validateStep4Form = (formData, setErrors, setStep, step) => {
    const newErrors = {};
    if (!formData.photoID) {
        newErrors.photoID = 'Photo ID / Passport is required';
    }
    if (!formData.proofOfAddress) {
        newErrors.proofOfAddress = 'Proof of Address is required';
    }

    // Loop through newErrors object and display toast for each error
    Object.keys(newErrors).forEach(field => {
        toast.error(newErrors[field], { position: "top-right" });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

const handleStep3Validation = async (formData, setStep, step, setLoading, loading) => {
    const errorsStep3 = validateStep3Form(formData);
    if (Object.keys(errorsStep3).length === 0) {
        setLoading(true)
        const response = await confirmIdentificationNumber(formData.identificationNumber, step, setStep, setLoading)
        setLoading(false)

    } else {
        console.log('Step 3 validation errors:', errorsStep3);
        Object.values(errorsStep3).forEach(error => {
            toast.error(error, { position: "top-right" });
        });
        setLoading(false)
    }
};


// Login Validation

const validateLoginFields = (formData, toast) => {
    const requiredFields = ['email', 'password'];
    let isValid = true;

    requiredFields.forEach(field => {
        if (!formData[field]) {
            toast.error(`Please enter ${field}`, { position: 'top-right' });
            isValid = false;
        } else if (field === 'email' && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData[field])) {
            toast('Please enter a valid email address', styles.toastStyles);
            isValid = false;
        }
        // else if (field === 'password' && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData[field])) {
        //     toast('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.', styles.toastStyles);
        //     isValid = false;
        // }
    });

    return isValid;
};
export { validateFormFields, validateFields, validateStep3Form, validateStep4Form, handleStep3Validation, validateLoginFields }