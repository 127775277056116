import React, { useState } from "react";
import styles from "../kycForm/step1.styles";
import logo from '../../assets/images/logo/logo.png';
import { signInService } from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contextAPI/AuthContext";
import { toast } from "react-toastify";
import { validateLoginFields } from "../kycForm/kyc.methods";

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { setUserObj, login } = useAuth()
    const nav = useNavigate()

    const handleSignIn = async () => {
        setLoading(true);
        try {
            let formData = {
                email, password
            }
            let validation = validateLoginFields(formData, toast)
            if (!validation) {
                return
            }
            const response = await signInService(email, password);
            console.log(response);
            if(response && response.userObj && response.userObj.userVerification){
                login(response.userObj,response.token)
                nav('/private-sale', { state: { user: response.userObj } });

            }
            else if ((response && response.userObj && !response.userObj.userVerification) || !response.userVerification) {

                console.log('resp msg:', response.userVerification);
                return toast(response.message, styles.toastStyles)
            }

        } catch (error) {
            toast(error.message, styles.toastStyles)
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section style={styles.sectionBackground} className="section-container">
            <form data-aos="fade-right" data-aos-duration="500" className="form-container" onSubmit={(e) => e.preventDefault()} style={{ display: "block" }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '100%', width: '100%', marginTop: "20px" }}>
                    <img className="flip-animation" style={{ height: "100px", width: '133px' }} src={logo} alt="Logo" />
                </div>
                <p className='color' style={{ fontSize: "23px", textAlign: "center", fontWeight: "600", margin: "10px 0px -7px 0px" }}>
                    LOGIN - REM TOKENS
                </p>
                <div className="form-inner-container marginB">
                    <div style={styles.container}>
                        <div style={styles.formContainer}>
                            <h3 style={styles.step} className='marginB'><span style={{ ...styles.step, fontStyle: 'italic', fontWeight: "700" }}></span></h3>
                            <div style={styles.form}>
                                <input
                                    style={styles.input}
                                    placeholder='Email:'
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <input
                                    style={styles.input}
                                    placeholder='Password:'
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <p style={{fontSize: "14px", fontStyle: "", cursor: "pointer",textAlign:"left",margin:"-14px 0px 0px 9px"}} onClick={() => nav('/forgot-password')}>Forgot Password?</p>
                        </div>
                    </div>
                </div>

                <div style={{ padding: "0px 30px", display: 'flex', justifyContent: "right", marginTop: "-15px" }} className="button-container marginB">
                    <div style={{ display: "flex", flexDirection: "column", fontSize: "15px", fontStyle: "italic", cursor: "pointer", marginBottom: "-15px" }}>
                        <button
                            type="button"
                            onClick={handleSignIn}
                            style={{ ...styles.button, marginTop: "0px", fontWeight: "bolder", width: "120px", opacity: loading ? 0.6 : 1 }}
                            disabled={loading}
                        >
                            {!loading ?
                                <h5 className="f-w-700 buttpn-text">
                                    SIGN IN
                                </h5> :
                                <h5 className="f-w-700">
                                    {loading ? <div style={{ marginLeft: "40px" }} className="loader"></div> : 'SIGN IN'}
                                </h5>}
                        </button>
                        <p onClick={() => nav('/sign-up')}>Register Now?</p>
                    </div>
                </div>
            </form>
        </section>
    );
}

export default SignIn;
