import React, { useEffect, useState } from "react";
import SwapComponent from "../components/swap/SwapComponent";
import { useNavigate } from "react-router-dom";

const Swap = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const nav = useNavigate()
    const isAuthenticated = () => {
        return localStorage.getItem('token') !== null;
    };

    useEffect(() => {
        const isToken = isAuthenticated();
        setAuthenticated(isToken);
        console.log('token',isToken);
        if (!isToken) {
            nav('/sign-in');
        }
    }, [nav]);


    return(
        <>
        <div style={{height:"100%"}}>
            {authenticated?<SwapComponent/>:<></>}
        
        </div>
        </>
    )
};

export default Swap;