import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";
import { AuthProvider } from "./components/contextAPI/AuthContext";
import { Web3ModalProvider } from './walletConnect/walletConnect';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <BrowserRouter>
            <AuthProvider>
                <ScrollToTop />
                <Web3ModalProvider>
                <App />
                </Web3ModalProvider>
            </AuthProvider>
        </BrowserRouter>
    </React.Fragment>
);

