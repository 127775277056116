// import SignIn from "../components/kycSignIn/SignIn";
// import Kyc from "./Kyc";
// import LandingPage from "./LandingPage";
// import Swap from "./Swap";

const menus = [
  {
    // id: 1,
    // name: 'Home',
    // links: '/',
    // showWhenLoggedIn: true,
    // showOnLgScreen: true,
  },
  // {
  //   id: 4,
  //   name: "Buy REM",
  //   links: "/private-sale",
  //   showWhenLoggedIn: true,
  //   showOnLgScreen: true,
  // },
  {
    id: 5,
    name: "WHITE PAPER",
    links: "/REM White Paper V1.0 (D7).pdf",
    pdf: true,
    showWhenLoggedIn: false,
    showOnLgScreen: false,
  },
  {
    id: 6,
    name: "TOKENOMICS",
    links: "/REM TOKENOMICS.pdf",
    pdf: true,
    showWhenLoggedIn: false,
    showOnLgScreen: false,
  },
];
// {
//     id: 2,
//     name: 'Roadmap',
//     links: '/roadmap',
//     showWhenLoggedIn: true,
//     showOnLgScreen: true,
// },
// {
//     id: 2,
//     name: 'Sign In',
//     links: '/sign-in',
//     showWhenLoggedIn: false,
//     showOnLgScreen: false,
// },
// {
//     id: 3,
//     name: 'Sign Up',
//     links: '/sign-up',
//     showWhenLoggedIn: false,
//     showOnLgScreen: false,
// },
// {
//     id: 5,
//     name: 'Log Out',
//     links: '/log-out',
//     showWhenLoggedIn: true,
//     showOnLgScreen: false,
// },
// {
//     id: 1,
//     name: '',
//     links: '/kyc',
// },
// {
//     id: 1,
//     name: 'Home',
//     links: '#',
//     namesub: [
//         {
//             id: 1,
//             sub: 'Home 01',
//             links: '/'
//         },
//         {
//             id: 2,
//             sub: 'Home 02',
//             links: '/home-v2'
//         },
//         {
//             id: 3,
//             sub: 'Home 03',
//             links: '/home-v3'
//         },
//     ]
// },
// {
//     id: 2,
//     name: 'About',
//     links: '',
// },
// {
//     id: 4,
//     name: 'Road Map',
//     links: '#',
//     namesub: [
//         {
//             id: 1,
//             sub: 'Road Map v1',
//             links: '/roadmap-v1'
//         },
//         {
//             id: 2,
//             sub: 'Road Map v2',
//             links: '/roadmap-v2'
//         },
//         {
//             id: 3,
//             sub: 'Road Map v3',
//             links: '/roadmap-v3'
//         },
//     ],
// },
// {
//     id: 5,
//     name: 'Pages',
//     links: '#',
//     namesub: [
//         {
//             id: 1,
//             sub: 'Sign in ',
//             links: '/signin'
//         },
//         {
//             id: 2,
//             sub: 'Sign up',
//             links: '/signup'
//         },
//         {
//             id: 3,
//             sub: 'Faq v1',
//             links: '/faq-v1'
//         },
//         {
//             id: 4,
//             sub: 'Faq v2',
//             links: '/faq-v2'
//         },
//         {
//             id: 5,
//             sub: 'Our Team',
//             links: '/our-team'
//         },
//         {
//             id: 6,
//             sub: 'Collection',
//             links: '/collection'
//         },
//         {
//             id: 7,
//             sub: 'Testimonial',
//             links: '/testimonial'
//         },
//         {
//             id: 8,
//             sub: 'Item Details',
//             links: '/item-details'
//         },
//         {
//             id: 9,
//             sub: 'Comming Soon',
//             links: '/comming-soon'
//         },
//         {
//             id: 10,
//             sub: 'Page 404',
//             links: '/page-404'
//         },
//     ],
// },
// {
//     id: 6,
//     name: 'Blog',
//     links: '#',
//     namesub: [
//         {
//             id: 1,
//             sub: 'Blog',
//             links: '/blog'
//         },
//         {
//             id: 2,
//             sub: 'Blog Details',
//             links: '/blog-details'
//         },
//     ],
// },
// {
//     id: 7,
//     name: 'Contact',
//     links: '/contact',
// },

export default menus;
