import remBg from '../../assets/images/background/rem-bg.jpeg'

const styles = {
    toastStyles: {
        position: "top-right",
        className: "toast-message"
    },
    swapToast : {
        background: "linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(51, 51, 51, 1) 100%)",
        color: "white",
        fontSize: "18px",
        fontWeight: "400",
        minWidth: "15vw",
        padding: "25px 28px",
        border: "1px solid #92D050",
        boxShadow: "-100px 0px 99px -23px rgba(146,208,80,0.55)",
        borderRight: "none",
        borderBottom: "none"
    },
    sectionBackground: {
        backgroundImage: `url(${remBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
        width: "100%",
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    formContainer: {
        // backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '0px 30px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignItems:"center",
        // height: '100vh',

        borderRadius: '10px',
        width: "100%",
        textAlign: 'center',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    alreadyButton: { display: "flex", flexDirection: "column", fontSize: "15px", fontStyle: "italic", cursor: "pointer", marginBottom: "-15px" },
    step: {
        color: 'rgb(184,136,61)',
        fontWeight:"400",
         fontStyle: 'italic',
        fontSize: '20px',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    countdown: {
        color: 'rgb(184,136,61)'
        , fontStyle: 'italic',
        fontSize: '20px',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    label: {
        color: 'rgb(184,136,61)',
        textAlign: 'left',
        marginBottom: '5px',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    input: {
        marginBottom: '15px',
        padding: '10px',
        border: '1px solid rgb(184,136,61)',
        outline: 'none',
        background: "none",
        fontFamily: 'Aptos, sans-serif', // Example font family

    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    checkboxLabel: {
        color: 'rgb(184,136,61)',
        marginLeft: '5px',
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    checkbox: {
        width: '30px',
        marginLeft: "5px", // Adjust the size as needed
        height: '30px', // Adjust the size as needed
        border: '1px solid rgb(184,136,61)',
        appearance: 'none', // Remove default styling
        WebkitAppearance: 'none', // Remove default styling for Webkit browsers
        MozAppearance: 'none', // Remove default styling for Mozilla browsers
        display: 'inline-block', // Ensure checkbox stays inline with label
        cursor: 'pointer' // Add a pointer cursor on hover
    },
    button: {
        height: "45px",
        width: "75%",
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: 'rgb(184,136,61)',
        color: '#000',
        background: 'rgb(153,101,19)',
        background: ' linear-gradient(180deg, rgba(153,101,19,1) 0%, rgba(214,163,59,1) 100%)',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginTop: "20px",
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
    chooseFileButton: {
        height: "50px",
        width: "30%",
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: 'rgb(184,136,61)',
        color: '#000',
        background: 'rgb(153,101,19)',
        background: ' linear-gradient(180deg, rgba(153,101,19,1) 0%, rgba(214,163,59,1) 100%)',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginTop: "20px",
        fontFamily: 'Aptos, sans-serif', // Example font family
    },
};


export default styles;
