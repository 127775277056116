import React, { useState } from 'react';
import './kycform.css';
import styles from './step1.styles';

const Step2 = ({ formData, handleChange }) => {
    const [errors, setErrors] = useState({});

    return (
        <div style={styles.formContainer}>
            <h2 style={styles.step}>
                <span style={{ ...styles.step, fontWeight: "400" }}>Step 2:</span> Create & Confirm Password
            </h2>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                <input
                    style={{ ...styles.input, flex: '1 1 45%' }}
                    className='formInput'
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                />
                {errors.firstName && <div className="error">{errors.firstName}</div>}
                <input
                    style={{ ...styles.input, flex: '1 1 45%' }}
                    className='formInput'
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                />
                {errors.lastName && <div className="error">{errors.lastName}</div>}
            </div>
            <input
                style={styles.input}
                className='formInput'
                type="text"
                name="walletAddress"
                placeholder="Non-custodial wallet / Metamask wallet"
                value={formData.walletAddress}
                onChange={handleChange}
            />
            {errors.walletAddress && <div className="error">{errors.walletAddress}</div>}
            <input
                style={styles.input}
                className='formInput'
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
            />
            {errors.username && <div className="error">{errors.username}</div>}
            <input
                style={styles.input}
                className='formInput'
                type="password"
                name="password"
                placeholder="Create Password"
                value={formData.password}
                onChange={handleChange}
            />
            {errors.password && <div className="error">{errors.password}</div>}
            <input
                style={styles.input}
                className='formInput'
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
            />
            {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}

        </div>
    );
};

export default Step2;
