import { toast } from "react-toastify";
import axios from "axios";
import styles from "../components/kycForm/step1.styles";
const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_DEV,
  // baseURL: "http://localhost:3004/",
  timeout: 500000000,
  headers: {
    "Content-Type": "application/json",
  },
});

const sendVerificationCode = async (email, phone) => {
  try {
    const response = await Axios.post(`/user/send-mail`, {
      email,
      phone,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error.response.data.message);
    toast(`Error: ${error?.response?.data?.message}`, styles.toastStyles);
    return error?.response?.data;
  }
};

const verifyUserCode = async (email, code, phone) => {
  try {
    const response = await Axios.post(`/user/verify-mail`, {
      email,
      code,
      phone,
    });
    return response.data;
  } catch (error) {
    console.error("Error verifying code:", error);
    return error;
  }
};

const saveFormData = async (data) => {
  try {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("walletAddress", data.walletAddress);
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("dob", data.dob);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("identificationNumber", data.identificationNumber);
    formData.append("photoID", data.photoID);
    formData.append("proofOfAddress", data.proofOfAddress);

    const response = await Axios.post(`/user/save-form`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast(response?.data.message, styles.toastStyles);

    return response.data;
  } catch (error) {
    console.error("Error saving user data:", error);
    toast.error(error.message, { position: "top-right" });
    return error;
  }
};

const signInService = async (email, password) => {
  try {
    const response = await Axios.post("/user/sign-in", {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    return new Error(error.response?.data?.message || "Sign-in failed");
  }
};

const confirmWalletAddress = async (walletAddress) => {
  try {
    walletAddress = walletAddress.toLowerCase();
    console.log(walletAddress);
    const response = await Axios.post(`/user/verify-wallet-address`, {
      walletAddress,
    });
    return response;
  } catch (error) {
    console.error("Error confirming wallet address:", error);
    // toast('Error: ' + error.response?.data?.message || error.message,styles.toastStyles);
    throw error;
  }
};

const confirmIdentificationNumber = async (
  identificationNumber,
  step,
  setStep,
  setLoading
) => {
  try {
    console.log(identificationNumber);
    const response = await Axios.post(`/user/verify-identification-number`, {
      identificationNumber,
    });
    setStep(step + 1);
    setLoading(false);
    return response;
  } catch (error) {
    console.error("Error confirming wallet address:", error);
    toast(
      "Error: " + error.response?.data?.message || error.message,
      styles.toastStyles
    );
    setLoading(false);
    return error;
  }
};

const getSignature = async (token) => {
  try {
    const response = await Axios.get("/user/signature", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error confirming wallet address:", error);
    // toast('Error: ' + error.response?.data?.message || error.message,styles.toastStyles);
    return error;
  }
};

const sendTransactionEmail = async (
  email,
  amountPurchased,
  addressId,
  transactionId
) => {
  try {
    const response = await Axios.post("/user/transection-email", {
      email,
      amountPurchased,
      addressId,
      transactionId,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending transaction email:", error);

    throw error;
  }
};

const sendFpCode = async (email) => {
  try {
    const response = await Axios.post(`/user/user-verification-email`, {
      email,
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || "Failed to send verification code."
    );
  }
};

const verifyFpCode = async (email, code) => {
  try {
    const response = await Axios.post(`/user/verify-fp-code`, { email, code });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.message || "Failed to check verification code."
    );
  }
};

const resetPassword = async (email, password, code) => {
  try {
    code = parseInt(code);
    const response = await Axios.post(`/user/reset-password`, {
      email,
      password,
      code,
    });
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("Something went wrong");
  }
};
export {
  resetPassword,
  sendVerificationCode,
  verifyUserCode,
  saveFormData,
  signInService,
  confirmWalletAddress,
  confirmIdentificationNumber,
  getSignature,
  sendTransactionEmail,
  sendFpCode,
  verifyFpCode,
};
