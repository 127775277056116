import React from 'react';
import './kycform.css';
import FileInput from './FileInput';
import styles from './step1.styles';

const Step4 = ({ formData, handleChange }) => {
    return (
        <div style={styles.formContainer}>
            <h2 style={styles.step}><span style={{...styles.step,fontWeight:"400"}}>Step 4:</span> Documents Upload</h2>
            <FileInput
                label="1. Photo ID / Passport"
                name="photoID"
                handleChange={handleChange}
            />
            <FileInput
                label="2. Proof of Address"
                name="proofOfAddress"
                handleChange={handleChange}
            />
        </div>
    );
};

export default Step4;
