import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { useEffect, useState } from "react";
import { WagmiProvider } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

const WEB_URL = `${process.env.REACT_APP_WEBSITE_URL}`;
// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: WEB_URL, // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];

export function Web3ModalProvider({ children }) {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    createConfig();
  }, []);

  const createConfig = () => {
    let initialConfig = defaultWagmiConfig({
      chains,
      projectId,
      metadata,
      enableWalletConnect: true,
      enableCoinbase: false,
      enableEIP6963: false,
      enableInjected: true,
    });

    const httpsURL = WEB_URL.slice(8);
    const url = encodeURIComponent(WEB_URL);
    let customWallets, featuredWalletIds;

    if (window.innerWidth < 768 && typeof window.ethereum === "undefined") {
      featuredWalletIds = [];
      customWallets = [
        {
          id: "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
          name: "CoinBase (Mobile Browser Only)",
          image_url:
            "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
          mobile_link: `https://go.cb-w.com/dapp?cb_url=${url}`,
        },
        {
          id: "MetaMask (Mobile Only)",
          name: "MetaMask (Mobile Browser Only)",
          image_url:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png",
          mobile_link: `https://metamask.app.link/dapp/${httpsURL}`,
        },
        {
          id: "Trust Wallet (Mobile Only)",
          name: "Trust Wallet (Mobile Browser Only)",
          mobile_link: `https://link.trustwallet.com/open_url?coin_id=60&url=${WEB_URL}`,
          image_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiYUPyaVpa2MB2vGUwaJJG5DoXZQYqzcKKk3wkL9RwTw&s",
        },
      ];
    } else {
      initialConfig = defaultWagmiConfig({
        chains,
        projectId,
        metadata,
        enableWalletConnect: true,
        enableCoinbase: true,
        enableEIP6963: true,
        enableInjected: true,
      });
    }

    createWeb3Modal({
      wagmiConfig: initialConfig,
      projectId,
      customWallets,
      featuredWalletIds,
      themeVariables: {
        "--w3m-accent": "#feb44a",
        "--wcm-z-index": "-10",
      },
    });

    setConfig(initialConfig);
  };

  if (!config) return null;

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
